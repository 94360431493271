
import { IonPage, IonCard, IonItem, IonLabel, IonButton, IonInput
  , IonCardHeader , IonCardTitle, IonLoading} from '@ionic/vue'
import { logIn, personAdd } from 'ionicons/icons';
import { useVuelidate } from "@vuelidate/core";
import {  email,maxLength, minLength, required, sameAs, helpers } from "@vuelidate/validators";

// const loginPattern = helpers.regex('alpha', /^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$/);
const userPattern = helpers.regex(/^(?=[a-zA-Z0-9._]{2,50}$)(?!.*[_.]{2})[^_.].*[^_.]$/);


export default {
  name: 'Registration',
  components: {
    IonPage,
    IonCard,
    IonItem,
    IonLabel,
    IonButton,
    IonInput,
    IonCardTitle,
    IonCardHeader,
    IonLoading,
  },
  setup() {
    return {
      logIn,
      personAdd,
      v: useVuelidate()
    };
  },
  data() {
    return {
      user: {
        username: '',
        password: '',
        passwordConfirm:'',
        email:'',
        firstName:'',
        lastName:''
      },
      success: false,
      loading: false,
      errorUserExists: false,
      errorEmailExists: false,
      error: false
    }
  },
  validations(){
    return{
      user: {
        email: {
          required,
          email
        },
        password: {
          required,
          minlength: minLength(8),
          maxlength: maxLength(254)
        },
        passwordConfirm:{
          sameAsPassword: sameAs(this.user.password)
        },
        // username: {
        //   required,
        //   minlength: minLength(2),
        //   maxlength: maxLength(50),
        //   userPattern: helpers.withMessage('Invalid username', userPattern),
        // },
        firstName:{
          required,
          minlength: minLength(2),
          maxlength: maxLength(50),
        },
        lastName:{
          required,
          minlength: minLength(2),
          maxlength: maxLength(50),
        }
      }

    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {

   async handleRegister(user) {
      this.message = '';
      this.loading = true;
      this.errorUserExists =false;
      this.errorEmailExists =false;
      this.error = false;
     const result = await this.v.$validate();

  if(!result){
    this.loading =false;
    return
  }else{
    this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.loading = false;
          this.success = true;
          this.reset();
           // this.$router.replace("/login");
        },
        (error) => {
          if (error.response.status === 400 && error.response.data.message === 'error.userexists') {
            this.errorUserExists = true;
          } else if (error.response.status === 400 && error.response.data.message === 'error.emailexists') {
            this.errorEmailExists = true;
          } else {
            this.error = true;
          }
          this.success = false;
          this.loading = false;
        }
    );
  }

    },
    reset(){
      this.$refs.form.reset();
     // this.v.$reset();
    }

  }
}

